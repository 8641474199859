.CreateUserForm .checkbox {
  cursor: pointer;
}
.CreateUserForm .checkbox i {
  margin-right: 5px;
}
.CreateUserForm .full-width.indented {
  margin-left: 4%;
  width: 96%;
}
.CreateUserForm .sms-stop-warning {
  border-radius: 5px;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  background-color: #FFBF00;
  color: black;
  padding: 5px;
  margin-left: 4%;
  width: 96%;
}
.CreateUserForm .extended-hr {
  margin-left: -15px;
  margin-right: -15px;
}
/*# sourceMappingURL=CreateUserForm.css.map */