.ApplicationModal .modal-footer {
  text-align: center;
}
.ApplicationModal .full-width {
  width: 100%;
}
.ApplicationModal .validation-ok {
  display: none;
}
.ApplicationModal .validation-error {
  color: red;
}
.ApplicationModal .multiline-display {
  overflow-x: hidden;
  overflow-y: auto;
  white-space: pre-wrap;
  max-height: 200px;
  font-family: inherit;
  font-size: 16px;
}
.ApplicationModal .primary-item-list {
  list-style-image: url("/src/greenBar20.png");
  padding-left: 10px;
  text-align: left;
  margin-bottom: 0px;
}
.ApplicationModal .primary-item-list li {
  font-size: 28px;
  padding-left: 10px;
}
.ApplicationModal .modal-dialog .modal-content .modal-title {
  width: 100%;
}
.ApplicationModal .modal-dialog .modal-content .modal-footer .btn.btn-primary {
  border-radius: 25px;
  border-color: var(--rounded-button-background-color);
  border-width: 1px;
  border-style: solid;
  background-color: var(--rounded-button-background-color);
  margin-top: 5px;
  display: inline-block;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: white !important;
  font-size: 20px;
  transition-duration: 200ms;
  cursor: pointer;
}
.ApplicationModal .modal-dialog .modal-content .modal-footer .btn.btn-primary:hover {
  background-color: var(--rounded-button-background-color-lightened-10);
}
.ApplicationModal .modal-dialog .modal-content .modal-footer .btn.btn-secondary {
  border-radius: 25px;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  background-color: transparent;
  color: black;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 5px;
  font-size: 20px;
}
.ApplicationModal .modal-dialog .modal-content .modal-footer .btn.btn-secondary:hover {
  color: black;
  background-color: var(--gray-border-color);
}
.ApplicationModal .modal-dialog .modal-content .modal-header {
  padding-bottom: 10px;
}
.ApplicationModal .modal-dialog .modal-content .modal-header .btn.btn-secondary {
  border-radius: 20px;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  background-color: transparent;
  color: var(--gray-border-color);
  border: none;
  padding: 0px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 32px;
}
.ApplicationModal .modal-dialog .modal-content .modal-header .btn.btn-secondary:hover {
  color: black;
  background-color: var(--gray-border-color);
}
/*# sourceMappingURL=ApplicationModal.css.map */