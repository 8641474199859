.CannabisScore {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
}
.CannabisScore .gradient-container {
  position: relative;
  width: 100%;
  height: 70px;
  /* Increase height to accommodate pointer */
  /* Gradient bar */
  /* Marker (pointer and label) */
  /* Pointer (the triangle) */
  /* Value label */
  /* Labels below the gradient */
}
.CannabisScore .gradient-container .gradient-bar {
  width: 100%;
  height: 20px;
  border-radius: 5px;
  background: linear-gradient(to right, #E57373, #FFB74D, #81C784);
  position: absolute;
  top: 0px;
  /* Adjusted to center within the container */
}
.CannabisScore .gradient-container .marker {
  position: absolute;
  bottom: 65px;
  /* Position above the gradient bar */
  transform: translateX(-50%);
  /* Center the marker horizontally */
  text-align: center;
  left: 0;
}
.CannabisScore .gradient-container .pointer {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
  margin-left: -1px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.CannabisScore .gradient-container .value-label {
  font-size: 18px;
  font-weight: bold;
}
.CannabisScore .gradient-container .labels {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  position: relative;
}
.CannabisScore .gradient-container .labels.upper {
  top: 20px;
}
.CannabisScore .gradient-container .labels.lower {
  top: -10px;
  padding-left: 1px;
  padding-right: 1px;
}
.CannabisScore .gradient-container .labels.lower span {
  width: 18%;
}
/*# sourceMappingURL=CannabisScore.css.map */