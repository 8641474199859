.SubjectScanCard {
  margin-top: 0px;
  margin-bottom: 40px;
  background-color: white;
  padding: 10px;
  width: 340px;
  height: 600px;
  max-height: 600px;
  box-shadow: 3px 3px 6px black;
}
.SubjectScanCard .centered {
  margin-left: auto;
  margin-right: auto;
  display: table;
}
.SubjectScanCard .data-label {
  text-align: right;
}
.SubjectScanCard .left {
  text-align: left;
  padding-left: 0px;
}
.SubjectScanCard .data-value {
  text-align: left;
  padding-left: 0px;
}
.SubjectScanCard .data-value.days-ago {
  font-size: 14px;
}
.SubjectScanCard .selection-button {
  height: 24px;
  padding-top: 0px;
  margin: 0px;
}
.SubjectScanCard .selection-button.btn-secondary.active {
  background-color: var(--rounded-button-background-color);
}
.SubjectScanCard .selection-button.btn-secondary:not(.active) {
  cursor: pointer;
}
.SubjectScanCard hr {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: -10px;
  margin-right: -10px;
}
.SubjectScanCard .bold {
  font-weight: bold;
}
.SubjectScanCard .dropdown-list .rounded-button-small .glyph-label .fa {
  margin-right: 10px;
  margin-top: 8px;
}
.SubjectScanCard .form-container .fa-caret-down {
  width: 20px;
}
.SubjectScanCard .form-container .fa-caret-right {
  width: 20px;
}
.SubjectScanCard .rounded-button-small {
  border-radius: 5px;
  border-color: var(--rounded-button-border-color);
  border-width: 1px;
  border-style: solid;
}
.SubjectScanCard .rounded-button-small .glyph-label .fa {
  margin-right: 0px;
  margin-top: 3px;
}
.SubjectScanCard .rounded-button-small:hover {
  border-radius: 5px;
  border-color: var(--rounded-button-background-color);
  border-width: 1px;
  border-style: solid;
}
.SubjectScanCard .comments-title {
  text-align: left;
  font-weight: bold;
}
.SubjectScanCard .comment-action {
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.SubjectScanCard .comment-action .fa-xmark {
  color: var(--fa-x-color);
  padding-top: 1px;
  padding-left: 0px;
  padding-right: 0px;
}
.SubjectScanCard .comment-action .fa-file-pdf {
  color: var(--default-text-color);
}
.SubjectScanCard .comment-action .action i {
  margin-right: 10px;
  width: 15px;
}
.SubjectScanCard .action-spacing {
  width: 5px;
}
.SubjectScanCard .title-row {
  min-height: 40px;
}
.SubjectScanCard .full-height {
  display: grid;
  height: 100%;
}
.SubjectScanCard .vertical-middle {
  align-self: center;
}
.SubjectScanCard .clickable {
  cursor: pointer;
}
.SubjectScanCard .fa {
  font-size: 14px;
}
.SubjectScanCard .fa-ban {
  color: var(--fa-x-color);
}
.SubjectScanCard .new-comment-button-row {
  margin-top: 10px;
}
.SubjectScanCard .scan-actions {
  margin-bottom: 10px;
  text-align: left;
}
.SubjectScanCard .scan-actions .action {
  font-weight: bold;
  font-size: 14px;
}
.SubjectScanCard .scan-actions .action i {
  margin-right: 10px;
  width: 15px;
}
.SubjectScanCard .comment-scroller {
  max-height: 160px;
  overflow-y: auto;
  overflow-x: hidden;
}
.SubjectScanCard .comment-scroller .comment-container {
  color: var(--comment-text-color);
  font-size: 14px;
  line-height: 1.2;
}
.SubjectScanCard .comment-scroller .comment-container .comment {
  padding: 2px 5px 10px 5px;
  cursor: pointer;
}
.SubjectScanCard .comment-scroller .comment-container .comment.readonly {
  cursor: initial;
}
.SubjectScanCard .box-score-container {
  margin-top: -10px;
}
.SubjectScanCard .extra-padding {
  padding-left: 20px;
  padding-right: 20px;
}
/*# sourceMappingURL=SubjectScanCard.css.map */