.CannabisScanCard .normality {
  color: black;
  text-transform: uppercase;
  font-size: 20px;
  margin-left: 10px;
}
.CannabisScanCard .normality.indeterminant {
  color: orange;
}
.CannabisScanCard .normality.abnormal {
  color: red;
}
.CannabisScanCard .normality.normal {
  color: green;
}
.CannabisScanCard .scan-result {
  margin-top: 10px;
}
.CannabisScanCard .upload-incomplete {
  color: red;
  text-transform: uppercase;
}
.CannabisScanCard .cannabis-score-container {
  margin-left: -20px;
  margin-right: -20px;
}
.CannabisScanCard .data-value {
  user-select: text !important;
  -webkit-user-select: text !important;
  /* For Safari */
  -moz-user-select: text !important;
  /* For Firefox */
  -ms-user-select: text !important;
}
/*# sourceMappingURL=CannabisScanCard.css.map */