.OrganizationSubjects {
  padding: 0px;
}
.OrganizationSubjects .detail-header {
  font-size: 24px;
  display: -webkit-box;
}
.OrganizationSubjects .detail-header input {
  border-radius: 10px;
  border-color: var(--gray-border-color);
  border-width: 1px;
  border-style: solid;
  padding: 5px 12px;
  font-size: 14pt;
}
.OrganizationSubjects .detail-body {
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 18px;
}
.OrganizationSubjects .patient-row {
  cursor: pointer;
  padding-top: 5px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14pt;
}
.OrganizationSubjects .patient-row .dropdown-list {
  padding: 0px;
  padding-left: 5px;
  width: 0px;
}
.OrganizationSubjects .patient-row .dropdown-list .rounded-button-small {
  width: 148px;
  text-align: left;
}
.OrganizationSubjects .table-header {
  padding-left: 12px;
  padding-right: 12px;
}
.OrganizationSubjects .patient-row:hover .data-cell {
  background-color: #eeeeee;
}
.OrganizationSubjects .table-rounded-container hr {
  margin-left: 0px;
  margin-right: 0px;
}
.OrganizationSubjects .patient-link {
  text-decoration: none;
  color: var(--default-text-color);
}
.OrganizationSubjects .data-cell .fa-hospital-user {
  margin-right: 10px;
}
.OrganizationSubjects .loaded-display {
  text-align: center;
  display: block;
}
.OrganizationSubjects .loaded-display a {
  text-decoration: none;
}
/*# sourceMappingURL=OrganizationSubjects.css.map */