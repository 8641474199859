.DeviceCard {
  border-radius: 10px;
  border-color: var(--gray-border-color);
  border-width: 1px;
  border-style: solid;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
  padding: 10px;
  cursor: pointer;
}
.DeviceCard .row {
  text-align: left;
}
.DeviceCard .fa-user {
  font-size: 48px;
  margin-left: 10px;
  margin-top: 10px;
}
.DeviceCard .data-label {
  font-weight: bold;
  text-align: right;
}
.DeviceCard .data-value {
  font-weight: normal;
  padding-left: 10px;
}
.DeviceCard .fa-xmark {
  color: red;
}
.DeviceCard .rounded-button-small .fa {
  margin-right: 10px;
}
.DeviceCard .dropdown-list {
  width: 0px;
}
.DeviceCard .dropdown-list .rounded-button-small {
  text-align: left;
  width: 138px;
}
.DeviceCard .no-hand {
  cursor: initial;
}
.DeviceCard img {
  width: 64px;
}
.DeviceCard .LoadingWidget {
  margin-top: -48px;
  margin-left: -24px;
}
/*# sourceMappingURL=DeviceCard.css.map */