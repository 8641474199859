.LoadingWidget {
  font-size: 36px;
  width: 100%;
}
.LoadingWidget .spinner {
  text-align: center;
}
.LoadingWidget .spinner .need-top-margin {
  margin-top: 50px;
}
.LoadingWidget .spinner .need-top-margin.small {
  margin-top: 10px;
}
.LoadingWidget .spinner .need-left-margin {
  margin-left: 30px;
}
/*# sourceMappingURL=LoadingWidget.css.map */