.ApplicationTitle {
  color: var(--light-text-color);
  font-size: 20px;
}
.ApplicationTitle .space-evenly {
  justify-content: space-evenly;
  display: flex;
}
.ApplicationTitle .tab {
  justify-content: initial;
  font-size: 16px;
  padding: 5px;
  display: block;
  pointer-events: all;
  cursor: pointer;
}
.ApplicationTitle .selected {
  border-radius: 5px;
  border-color: var(--light-text-color);
  border-width: 1px;
  border-style: solid;
}
/*# sourceMappingURL=ApplicationTitle.css.map */