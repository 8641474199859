.SubjectCard {
  border-radius: 10px;
  border-color: var(--gray-border-color);
  border-width: 1px;
  border-style: solid;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
}
.SubjectCard .large-icon {
  font-size: 48px;
  padding: 5px;
}
.SubjectCard .medium-icon {
  font-size: 24px;
}
.SubjectCard hr {
  margin-bottom: 5px;
}
.SubjectCard .labels {
  font-weight: normal;
}
.SubjectCard .scan-line {
  border-top: 1px solid var(--gray-border-color);
  min-height: 50px;
}
.SubjectCard .scan-line :hover {
  background-color: #ccffcc;
}
.SubjectCard .scan-line label {
  font-weight: normal;
  margin-right: 5px;
}
.SubjectCard a {
  text-decoration: none;
}
.SubjectCard li .fa {
  margin-right: 10px;
}
.SubjectCard li a {
  white-space: nowrap;
}
.SubjectCard .notes-display {
  cursor: pointer;
}
.SubjectCard .data-label {
  text-align: right;
  padding-right: 10px;
}
.SubjectCard .data-val {
  font-weight: bold;
}
/*# sourceMappingURL=SubjectCard.css.map */