.ConfigureSiteForm .rounded-button-small {
  border-radius: 10px;
  border-color: green;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  padding: 5px;
  margin-right: 2px;
  cursor: pointer;
  background-color: inherit;
  color: inherit;
  transition-duration: 200ms;
}
.ConfigureSiteForm .rounded-button-small:hover {
  background-color: var(--rounded-button-background-color-lightened-40);
}
.ConfigureSiteForm .up-down-buttons {
  padding: 0px;
  padding-left: 5px;
  padding-top: 12px;
  text-align: right;
}
.ConfigureSiteForm .dummy-button {
  width: 26px;
  display: inline-block;
}
.ConfigureSiteForm .middle {
  text-align: center;
}
.ConfigureSiteForm .selected {
  border-radius: 5px;
  border-color: gray;
  border-width: 1px;
  border-style: solid;
  border-bottom: 1px transparent solid;
  margin-bottom: -1px;
  z-index: 100;
  background-color: white;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ConfigureSiteForm .mode {
  cursor: pointer;
}
.ConfigureSiteForm .title-row {
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 0px;
  margin-left: 0px;
}
.ConfigureSiteForm .title-row label {
  margin-bottom: 0px;
  height: 24px;
}
.ConfigureSiteForm .config-panel {
  height: 400px;
  padding-left: 5px;
  padding-right: 20px;
  padding-top: 20px;
  overflow-y: auto;
  overflow-x: auto;
  border: 1px solid gray;
}
.ConfigureSiteForm .config-panel .up-down-buttons {
  padding-top: 0px;
}
.ConfigureSiteForm .device-model {
  border-radius: 10px;
  border-color: gray;
  border-width: 1px;
  border-style: solid;
  padding: 10px;
  margin-bottom: 10px;
}
.ConfigureSiteForm .add-model-button {
  border-radius: 10px;
  border-color: green;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  padding: 5px;
  margin-right: 2px;
  cursor: pointer;
  background-color: inherit;
  color: inherit;
  transition-duration: 200ms;
  margin-right: 0px;
  margin-bottom: 10px;
}
.ConfigureSiteForm .add-model-button:hover {
  background-color: var(--rounded-button-background-color-lightened-40);
}
/*# sourceMappingURL=ConfigureSiteForm.css.map */