.SubjectDetail .back-link {
  font-weight: bold;
  color: var(--default-text-color);
  text-align: left;
}
.SubjectDetail .back-link i {
  margin-right: 10px;
  font-size: 24px;
}
.SubjectDetail .back-link a {
  font-weight: bold;
  color: var(--default-text-color);
}
.SubjectDetail .back-link span {
  vertical-align: top;
}
.SubjectDetail .data-label {
  text-align: right;
  padding-right: 10px;
}
.SubjectDetail .data-value {
  text-align: left;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.SubjectDetail .rounded-button-blue {
  font-size: 16px;
}
.SubjectDetail .rounded-button-blue-disabled {
  font-size: 16px;
}
.SubjectDetail .scan-row {
  cursor: pointer;
  line-height: 1;
  font-size: 16px;
  margin-top: 5px;
}
.SubjectDetail .scan-row .dropdown-list {
  padding: 0px;
  padding-left: 5px;
  width: 250px;
}
.SubjectDetail .scan-row .dropdown-list .rounded-button-small {
  width: 225px;
  text-align: left;
}
.SubjectDetail .scan-row .data-cell {
  padding-top: 10px;
}
.SubjectDetail .scan-row .dropdown {
  margin: 0px;
  padding: 0px;
}
.SubjectDetail .scan-row:hover .data-cell {
  background-color: #eeeeee;
}
.SubjectDetail .rounded-button-small .glyph-label {
  display: inline-flex;
}
.SubjectDetail .h-ellipses-button {
  height: 30px;
  padding-top: 5px;
  margin-top: -5px;
}
.SubjectDetail .table-rounded-container {
  text-align: left;
}
.SubjectDetail .carousel-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
.SubjectDetail .carousel-container.full-width {
  margin-left: -20px;
  margin-right: -20px;
  width: 110%;
}
.SubjectDetail .carousel-container .swiper .swiper-slide .report-page .SubjectScanCard {
  margin-left: auto;
  margin-right: auto;
}
/*# sourceMappingURL=SubjectDetail.css.map */