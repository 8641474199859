.PlayOrDownloadVideoForm {
  text-align: center;
  font-size: 18px;
}
.PlayOrDownloadVideoForm .btn.btn-primary {
  border-radius: 25px;
  border-color: var(--rounded-button-background-color);
  border-width: 1px;
  border-style: solid;
  background-color: var(--rounded-button-background-color);
  margin-top: 5px;
  display: inline-block;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: white !important;
  font-size: 20px;
  transition-duration: 200ms;
  cursor: pointer;
}
.PlayOrDownloadVideoForm .btn.btn-primary:hover {
  background-color: var(--rounded-button-background-color-lightened-10);
}
.PlayOrDownloadVideoForm .download-link {
  display: inline-block;
  margin-top: 15px;
  text-decoration: underline;
  color: var(--rounded-button-background-color);
}
/*# sourceMappingURL=PlayOrDownloadVideoForm.css.map */