.ApplicationToast {
  text-align: center;
  opacity: 90%;
  top: 0px;
  position: fixed;
  perspective: 240px;
  width: 100%;
  pointer-events: none;
  z-index: 1100;
}
.ApplicationToast .toast-panel {
  border-radius: 10px;
  border-color: var(--light-text-color);
  border-width: 1px;
  border-style: solid;
  background-color: black;
  color: var(--light-text-color);
  text-align: center;
  font-size: 24px;
  opacity: 100;
  pointer-events: all;
  width: 100%;
}
.ApplicationToast .toast-panel .header {
  height: 40px;
  border-bottom: 1px white solid;
}
.ApplicationToast .toast-panel .header .info-cell {
  text-align: left;
  padding-left: 13px;
}
.ApplicationToast .toast-panel .body {
  min-height: calc(240px - 40px - 2px);
  font-size: 20px;
  padding: 5px;
}
.ApplicationToast .toast-panel .footer {
  height: 40px;
  border-top: 1px white solid;
}
.ApplicationToast .close-button {
  cursor: pointer;
  margin-right: 10px;
  margin-top: 5px;
  color: red;
  font-size: 16px;
  width: 28px;
  height: 28px;
  padding: auto;
  font-weight: bold;
  border-radius: 5px;
  border-color: red;
  border-width: 1px;
  border-style: solid;
}
.slide-in {
  transition-duration: 400ms;
  transform: translate3d(0px, 0px, 0px);
  perspective: 240px;
  width: 100%;
}
.slide-out {
  transition-duration: 400ms;
  transform: translate3d(0px, -240px, -240px);
  perspective: 240px;
  width: 100%;
}
/*# sourceMappingURL=ApplicationToast.css.map */