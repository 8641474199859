.BOXScore {
  padding-top: 20px;
  padding-bottom: 10px;
}
.BOXScore .box-label {
  color: #444;
}
.BOXScore .box-label.error {
  font-size: 12px;
}
.BOXScore .box-bar {
  border-radius: 5px;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  margin-top: 35px;
  background-color: #999;
  position: relative;
  width: 100%;
  height: 15px;
}
.BOXScore .box-bar .marker {
  position: absolute;
  top: -15px;
  width: 2px;
  background-color: #4b77a3;
  height: 29px;
}
.BOXScore .box-bar .marker .marker-circle {
  border-radius: 4px;
  border-color: #4b77a3;
  border-width: 2px;
  border-style: solid;
  position: absolute;
  top: -8px;
  height: 8px;
  width: 8px;
  left: -3px;
}
.BOXScore .box-bar .marker .marker-callout {
  position: absolute;
  border-radius: 3px;
  border-color: #4b77a3;
  border-width: 2px;
  border-style: solid;
  height: 18px;
  width: 70px;
  top: -13px;
  color: #4b77a3;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
}
.BOXScore .box-bar .marker .marker-callout.less-than-10 {
  left: 15px;
}
.BOXScore .box-bar .marker .marker-callout.greater-than-10 {
  left: -82px;
}
.BOXScore .box-bar .marker .marker-callout-tie-in {
  position: absolute;
  height: 2px;
  background-color: #4b77a3;
  width: 10px;
  top: -5px;
}
.BOXScore .box-bar .marker .marker-callout-tie-in.less-than-10 {
  left: 5px;
}
.BOXScore .box-bar .marker .marker-callout-tie-in.greater-than-10 {
  left: -13px;
}
.BOXScore .box-scale {
  color: #444;
  text-align: center;
  white-space: nowrap;
  display: flex;
}
.BOXScore .box-scale.error {
  font-size: 12px;
}
.BOXScore .box-scale div {
  font-size: 10px;
  display: inline-block;
  width: 33%;
  vertical-align: middle;
}
/*# sourceMappingURL=BOXScore.css.map */