.OrganizationResources {
  padding: 0px;
}
.OrganizationResources .item-row {
  display: inline-flex;
  padding-top: 10px;
  padding-bottom: 10px;
}
.OrganizationResources .item-row span {
  margin-top: auto;
  margin-bottom: auto;
}
.OrganizationResources .item-row img {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px;
  max-height: 50px;
  max-width: 50px;
}
/*# sourceMappingURL=OrganizationResources.css.map */