@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe1mMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp5F5bxqqtQ1yiU4G1ilXs1Ug.ttf) format('truetype');
}
.App {
  font-family: "Nunito Sans";
}
.App .row {
  margin: 0px;
}
.App .header {
  height: 80px;
  background-color: var(--header-background-color);
  text-align: center;
  vertical-align: middle;
}
.App .header a {
  text-decoration: none;
  background-color: #01000000;
  color: var(--light-text-color);
  transition-duration: 100ms;
}
.App .header a:hover {
  color: var(--light-text-color-darkened-25);
}
.App .header a.rounded-button {
  background-color: var(--rounded-button-background-color);
}
.App .left {
  min-height: 1px;
  text-align: left;
}
.App .middle {
  min-height: 1px;
  text-align: center;
}
.App .right {
  min-height: 1px;
  text-align: right;
}
.App .non-header {
  overflow-y: auto;
  min-height: calc(100vh - 80px);
  height: 0px;
}
.App .content {
  min-height: calc(100vh - 80px - 147px);
  padding-left: 5px;
  padding-right: 5px;
}
.App .full-width {
  width: 100%;
}
.App .centered {
  text-align: center;
}
.App .logo {
  height: 48px;
}
.App .swoop {
  height: 147px;
  width: 100%;
}
.App .footer {
  height: 147px;
  width: 100%;
  background-color: var(--header-background-color);
  color: var(--light-text-color);
  font-family: "Nunito Sans", sans-serif;
}
.App .footer .cell {
  margin-top: 10px;
}
.App .footer .icon-circle {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  background-color: white;
  padding-left: 6px;
  padding-top: 3px;
  margin-right: 10px;
}
.App .footer .icon-circle .fab {
  color: var(--header-background-color);
}
.App .footer .contact-us-div .contact-us-p .contact-us-span .contact-us-a {
  color: var(--light-text-color);
}
.App .copyright-footer {
  background-color: #0C1727;
  color: var(--light-text-color);
  text-align: center;
  font-weight: 400;
  font-family: 'Nunito Sans', sans-serif;
  height: 100px;
}
.App .copyright-footer-content {
  position: relative;
  top: 40px;
}
.App .h-center {
  margin-right: auto;
  margin-left: auto;
}
.App .v-center {
  margin-top: auto;
  margin-bottom: auto;
}
.App .small-icon {
  font-size: 10px;
  vertical-align: text-bottom;
  margin: 5px;
}
.App .bold-text {
  font-weight: 700;
}
.App .dropdown-menu {
  line-height: 36px;
  background-color: var(--primary-background-color);
  text-align: center;
}
.App .dropdown-menu a {
  padding: 2px 15px;
  border-radius: 5px;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  color: var(--default-text-color);
}
.App .dropdown-menu a:hover {
  cursor: pointer;
  color: var(--default-text-color);
  background-color: var(--primary-background-color-darkened-10);
  border-radius: 5px;
  border-color: var(-default-text-color);
  border-width: 1px;
  border-style: solid;
}
.App .dropdown-menu hr {
  margin-top: 3px;
  margin-bottom: 3px;
}
.App .dropdown-toggle {
  display: -webkit-box;
}
.App .dropdown-list {
  padding: 0px 10px;
  width: 250px;
}
.App .wrap-word {
  overflow-wrap: break-word;
}
.App .rounded-button {
  border-radius: 25px;
  border-color: var(--rounded-button-border-color);
  border-width: 2px;
  border-style: solid;
  background-color: var(--rounded-button-background-color);
  margin-top: 5px;
  min-width: 200px;
  display: inline-block;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
.App .rounded-button:hover {
  background-color: var(--rounded-button-background-color-darkened-20);
}
.App .rounded-button-small {
  border-radius: 10px;
  border-color: green;
  border-width: 1px;
  border-style: solid;
  margin-top: 3px;
  min-width: 40px;
  display: inline-block;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  background-color: inherit;
  color: inherit;
  transition-duration: 200ms;
}
.App .rounded-button-small:hover {
  background-color: var(--rounded-button-background-color-40);
}
.App .h-ellipses-button {
  border-radius: 10px;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  margin-top: 2px;
  margin-bottom: 2px;
  min-width: 40px;
  display: inline-block;
  text-align: center;
  padding: 2px;
  cursor: pointer;
  background-color: inherit;
  color: inherit;
  transition-duration: 200ms;
}
.App .h-ellipses-button:hover {
  background-color: var(--rounded-button-background-color-lightened-40);
  border-radius: 10px;
  border-color: gray;
  border-width: 1px;
  border-style: solid;
}
.App .rounded-button-blue {
  border-radius: 25px;
  border-color: var(--rounded-button-background-color);
  border-width: 1px;
  border-style: solid;
  background-color: var(--rounded-button-background-color);
  margin-top: 5px;
  display: inline-block;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: white !important;
  font-size: 20px;
  transition-duration: 200ms;
  cursor: pointer;
}
.App .rounded-button-blue:hover {
  background-color: var(--rounded-button-background-color-lightened-10);
}
.App .rounded-button-blue-disabled {
  border-color: var(--rounded-button-background-color);
  background-color: var(--rounded-button-background-color);
  margin-top: 5px;
  display: inline-block;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: white !important;
  font-size: 20px;
  transition-duration: 200ms;
  cursor: pointer;
  border-radius: 25px;
  border-color: #AAAAAA;
  border-width: 1px;
  border-style: solid;
  background-color: #AAAAAA;
  cursor: initial;
}
.App .rounded-button-blue-disabled:hover {
  background-color: var(--rounded-button-background-color-lightened-10);
}
.App .rounded-button-blue-disabled:hover {
  background-color: #AAAAAA;
}
.App .table-rounded-container {
  border-radius: 10px;
  border-color: #cccccc;
  border-width: 1px;
  border-style: solid;
  background-color: white;
}
.App .table-rounded-container hr {
  margin: 0px;
}
.App .table-header-separator {
  margin-top: 0px;
  margin-bottom: 10px;
}
.App .table-header {
  font-size: 14pt;
  font-weight: bold;
}
.App .primary-item-list {
  list-style-image: url("/src/greenBar20.png");
  padding-left: 10px;
  text-align: left;
}
.App .primary-item-list li {
  font-size: 28px;
  padding-left: 10px;
}
.App .secondary-item-list {
  list-style-type: "-";
  padding-left: 5px;
  text-align: left;
}
.App .secondary-item-list li {
  padding-left: 15px;
  font-size: 18px;
  color: #555555;
}
.App .default-menu-item {
  font-weight: bold;
}
.App .confined-width {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}
.App .logo-container.mobile {
  display: flex;
  align-items: center;
}
.App .logo-container .cloud-access {
  vertical-align: bottom;
  display: inline-flex;
  font-size: 18px;
  margin-bottom: 3px;
}
.App .logo-container .cloud-access.mobile {
  font-size: 16px;
  line-height: 1.1;
  margin-bottom: 0px;
  max-width: 80px;
}
/*# sourceMappingURL=App.css.map */