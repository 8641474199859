.UserCard {
  border-radius: 10px;
  border-color: var(--gray-border-color);
  border-width: 1px;
  border-style: solid;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
  padding: 10px;
  cursor: pointer;
}
.UserCard .row {
  text-align: left;
}
.UserCard .fa-user {
  font-size: 48px;
  margin-left: 10px;
  margin-top: 10px;
}
.UserCard .data-label {
  font-weight: bold;
}
.UserCard .data-value {
  font-weight: normal;
}
.UserCard .fa-xmark {
  color: red;
}
.UserCard .rounded-button-small .fa {
  margin-right: 10px;
}
.UserCard .dropdown-list {
  width: 0px;
}
.UserCard .dropdown-list .rounded-button-small {
  text-align: left;
  width: 138px;
}
.UserCard .no-hand {
  cursor: initial;
}
/*# sourceMappingURL=UserCard.css.map */