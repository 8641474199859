.Super {
  padding-left: 30px;
  padding-right: 15px;
}
.Super .detail-header {
  font-size: 24px;
  display: -webkit-box;
}
.Super .detail-header input {
  border-radius: 10px;
  border-color: var(--gray-border-color);
  border-width: 1px;
  border-style: solid;
  font-size: 14pt;
  padding: 5px 12px;
}
.Super .full-width {
  width: 100%;
}
.Super .full-width input {
  margin-top: 10px;
}
.Super .full-width .rounded-button-blue {
  margin-top: 10px;
}
.Super .full-width.table-header {
  padding-left: 14px;
  padding-right: 14px;
}
.Super .more-orgs {
  cursor: pointer;
  text-align: center;
}
.Super .organization-row {
  cursor: pointer;
  font-size: 14pt;
  padding: 0px 14px;
}
.Super .organization-row .dropdown-list {
  padding: 0px;
  padding-left: 5px;
  width: 0px;
}
.Super .organization-row .dropdown-list .rounded-button-small {
  width: 148px;
  text-align: left;
}
.Super .organization-row .dropdown a {
  padding: 0;
}
.Super .organization-row .data-cell .h-ellipses-button {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 2px;
  padding-right: 3px;
}
.Super .organization-row .data-cell .h-ellipses-button .fa-ellipsis-h {
  margin-top: 5px;
}
.Super .organization-row:hover .data-cell {
  background-color: #eeeeee;
}
.Super .rounded-button-small .glyph-label {
  display: inline-flex;
}
.Super .rounded-button-small .glyph-label span {
  margin-top: 10px;
  margin-right: 10px;
}
.Super .disabled-org {
  color: #888888;
}
.Super .loaded-display {
  text-align: center;
  display: block;
}
.Super .loaded-display a {
  text-decoration: none;
}
/*# sourceMappingURL=Super.css.map */