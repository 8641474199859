.CreateDeviceForm .tab-controls {
  display: flex;
}
.CreateDeviceForm .tab-controls .tab-item {
  border-width: 1px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 0;
  border-color: #ccc;
  border-style: solid;
  width: 100px;
  text-align: center;
  color: #777;
  cursor: pointer;
  user-select: none;
  background-color: white;
}
.CreateDeviceForm .tab-controls .tab-item.active {
  margin-bottom: -1px;
  border-width: 2px;
  border-bottom: 0;
  border-color: #ccc;
  color: black;
}
.CreateDeviceForm .tab-item-bottom {
  margin-top: 0px;
  margin-left: -15px;
  margin-right: -15px;
  background-color: #ccc;
}
.CreateDeviceForm .tab-content {
  height: 480px;
}
.CreateDeviceForm .tab-content .info-msg {
  margin-left: 30px;
  font-weight: normal;
}
/*# sourceMappingURL=CreateDeviceForm.css.map */