.Organization {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  text-align: center;
}
.Organization .organization-header {
  text-align: left;
  width: 100%;
  padding-left: 10px;
  display: inline-flex;
}
.Organization .organization-header .label {
  font-weight: bold;
  padding-right: 10px;
}
.Organization .patient-col {
  min-height: 100px;
  padding: 10px;
  margin-bottom: 20px;
}
.Organization .detail-header {
  font-size: 24px;
  display: -webkit-box;
}
.Organization .detail-header input {
  border-radius: 10px;
  border-color: var(--gray-border-color);
  border-width: 1px;
  border-style: solid;
}
.Organization .detail-body {
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 20px;
}
.Organization .tab {
  justify-content: initial;
  font-size: 24px;
  padding: 5px;
  display: block;
  pointer-events: all;
  cursor: pointer;
  width: 100%;
  color: var(--default-text-color);
  text-decoration: none;
}
.Organization .selected {
  border-radius: 5px;
  border-color: var(--default-text-color);
  border-width: 1px;
  border-style: solid;
}
.Organization .full-width {
  width: 100%;
}
.Organization .full-width input {
  margin-top: 5px;
}
.Organization .sort-by {
  font-size: 24px;
  border: none;
  background-color: var(--primary-background-color);
}
.Organization .more-patients {
  cursor: pointer;
  text-align: center;
}
.Organization .UserCard .header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
/*# sourceMappingURL=Organization.css.map */