.password-container {
  position: relative;
}
.password-requirements {
  border-radius: 10px;
  border-color: #ccc;
  border-width: 1px;
  border-style: solid;
  display: none;
  /* Initially hidden */
  position: absolute;
  top: -200px;
  left: 0;
  background-color: #050505;
  padding: 10px;
  width: 300px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  line-height: 1em;
}
.password-container:focus-within .password-requirements {
  display: block;
}
.requirement {
  color: #CCC;
  margin-left: 15px;
}
.requirement:before {
  content: "\f111";
  /* Font Awesome's times-circle icon */
  font-family: "Font Awesome 5 Free";
  /* Specify the Font Awesome family */
  font-weight: 900;
  /* Ensure it uses the solid version of Font Awesome */
  color: #CCC;
  /* Red color for "bad" items */
  margin-right: 8px;
  /* Spacing between icon and text */
}
.requirement.major {
  color: #FF4C4C;
  margin-left: 0px;
}
.requirement.major:before {
  content: "\f057";
  /* Font Awesome's times-circle icon */
  font-family: "Font Awesome 5 Free";
  /* Specify the Font Awesome family */
  font-weight: 900;
  /* Ensure it uses the solid version of Font Awesome */
  color: #FF4C4C;
  /* Red color for "bad" items */
  margin-right: 8px;
  /* Spacing between icon and text */
}
.requirement.major.valid {
  color: green;
}
.requirement.major.valid:before {
  content: "\f00c";
  color: green;
}
.requirement.valid {
  color: green;
}
.requirement.valid:before {
  content: "\f00c";
  color: green;
}
.requirement .indented {
  margin-left: 25px;
}
/*# sourceMappingURL=DialogFormBase.css.map */