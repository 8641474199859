.Landing {
  padding: 25px 5px;
  width: 100%;
}
.Landing p {
  font-size: 20px;
}
.Landing .content-frame {
  height: 100%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.Landing .login-frame {
  padding: 20px;
  width: 300px;
  height: 300px;
  text-align: left;
  background-color: white;
  box-shadow: 0px 0px 15px 1px black;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  border-radius: 20px;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}
.Landing .sign-in-text-heavy {
  font-weight: bold;
  font-size: 18px;
}
.Landing .sign-in-text-light {
  font-weight: normal;
  font-size: 18px;
}
.Landing .sign-in-text-light a {
  color: var(--contact-link-color);
}
/*# sourceMappingURL=Landing.css.map */