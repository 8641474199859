.UnsubscribeEmailView {
  max-width: 300px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.UnsubscribeEmailView .unsubscribe-prompt {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
}
/*# sourceMappingURL=UnsubscribeEmailView.css.map */