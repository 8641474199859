.SecondaryTitle {
  color: var(--light-text-color);
  font-size: 20px;
  margin-bottom: -8px;
  margin-top: 4px;
}
.SecondaryTitle .space-evenly {
  justify-content: space-evenly;
  display: flex;
}
.SecondaryTitle .space-evenly .col {
  padding-left: 5px;
  padding-right: 5px;
}
.SecondaryTitle .tab {
  justify-content: initial;
  font-size: 14px;
  padding: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px;
  display: block;
  pointer-events: all;
  cursor: pointer;
}
.SecondaryTitle .selected {
  border-radius: 5px;
  border-color: var(--light-text-color);
  border-width: 1px;
  border-style: solid;
}
/*# sourceMappingURL=SecondaryTitle.css.map */